import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TopBar from "../../components/TopBar";
import Footer from "../../components/Footer";
import "./my-account.css";
import MyAccountTab from "./my-account-tab";

export default function MyAccount() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [notification, setNotification] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLogin, setShowLogin] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const fromCheckout = location.state?.fromCheckout || false;

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => setNotification(""), 3000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const loginData = { email_or_phone: loginEmail, password: loginPassword };

    fetch("https://admin.aristoc.co.ug:9443/api/v1/auth/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(loginData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.token) {
          localStorage.setItem("authToken", data.token);
          setNotification("Login successful");
          setIsLoggedIn(true);
          if (fromCheckout) {
            navigate("/pre-check", { state: { token: data.token } });
          }
        } else {
          setNotification("Login failed");
        }
      })
      .catch(() => setNotification("An error occurred. Please try again."));
  };

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    const registrationData = { f_name: firstName, l_name: lastName, email, phone, password };

    fetch("https://admin.aristoc.co.ug:9443/api/v1/auth/registration", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(registrationData),
    })
      .then((response) => response.json())
      .then((data) => {
        setNotification(data.token ? "User Created Successfully" : "Registration Failed");
      })
      .catch(() => setNotification("An error occurred. Please try again."));
  };

  return (
    <div>
      <TopBar />
      <div className="account-container">
        <h1 className="account-title">My Account</h1>
        {notification && <div className="account-notification">{notification}</div>}

        {!isLoggedIn ? (
          <div className="forms-container">
            <div className="form-column">
              {showLogin ? (
                <>
                  <h3 className="form-title">Login</h3>
                  <form onSubmit={handleLoginSubmit}>
                    <label>Email or Phone:</label>
                    <input type="text" placeholder="Enter your email or phone" value={loginEmail} onChange={(e) => setLoginEmail(e.target.value)} required />
                    <label>Password:</label>
                    <input type="password" placeholder="Enter your password" value={loginPassword} onChange={(e) => setLoginPassword(e.target.value)} required />
                    <button type="submit">Login</button>
                  </form>
                  <p style={{marginTop: '5px'}}>Don't have an account? <button className="toggle-button" onClick={() => setShowLogin(false)}>Register</button></p>
                </>
              ) : (
                <>
                  <h3 className="form-title">Register</h3>
                  <form onSubmit={handleRegisterSubmit}>
                    <label>First Name:</label>
                    <input type="text" placeholder="Enter your first name" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                    <label>Last Name:</label>
                    <input type="text" placeholder="Enter your last name" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                    <label>Email:</label>
                    <input type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <label>Phone:</label>
                    <input type="tel" placeholder="Enter your phone number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    <label>Password:</label>
                    <input type="password" placeholder="Enter your password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    <button type="submit" className="toggle-button">Register</button>
                  </form>
                  <p style={{marginTop: '5px'}}>Already have an account? <button className="toggle-button" onClick={() => setShowLogin(true)}>Login</button></p>
                </>
              )}
            </div>
          </div>
        ) : (
          <MyAccountTab />
        )}
      </div>
      <Footer />
    </div>
  );
}
